import './bootstrap';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    withCredentials: true,

});



$(document).ready(function() {
    const ticketSlider = $('#ticketSlider');
    const productIdHash = $('#productIdHash');
    const ticketCount = $('#ticketCount');
    const addToCartButton = $('.add-to-cart');

    ticketSlider.on('input', function() {
        const tickets = $(this).val();
        ticketCount.text(tickets);
    });

    addToCartButton.click(function(e) {
        e.preventDefault();
        const button = $(this);
        const tickets = ticketSlider.val();
        const addToCartUrl = button.attr('href');
        const productIdHashValue = productIdHash.val();

        // Добавление спиннера и изменение цвета кнопки
        button.prop('disabled', true).addClass('processing');
        button.find('span').html('<i class="spinner-border spinner-border-sm"></i> Processing...');

        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: { product_qty: tickets, product_id_hash: productIdHashValue },
            success: function(response) {
                // button.find('span').html(response.success);
                $(".totalSum").text(parseFloat(response.total_sum).toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    .replace('.', ','));

                setTimeout(function() {
                    button.find('span').text('Add tickets to basket');
                    button.prop('disabled', false).removeClass('processing');
                }, 2000);
            },
            error: function(xhr, status, error) {
                console.error(error);
                button.find('span').text('Error');
                setTimeout(function() {
                    button.find('span').text('Add tickets to basket');
                    button.prop('disabled', false).removeClass('processing');
                }, 2000);
            }
        });
    });



});


$(document).ready(function() {

    let setupFormBlock = function($formBlock) {
        const $inputField = $formBlock.find(".inputField");
        const $decreaseButton = $formBlock.find(".decreaseButton");
        const $increaseButton = $formBlock.find(".increaseButton");

        $decreaseButton.on("click", function() {
            if ($inputField.val() > parseInt($inputField.attr("min"))) {
                $inputField.val(parseInt($inputField.val()) - 1);
                if (typeof $inputField.data("product-id") !== 'undefined') {
                    updateCartItemQty($inputField.data("product-id"), $inputField.data("product-hash"), $inputField.val());
                }

            }
        });

        $increaseButton.on("click", function() {
            if ($inputField.val() < parseInt($inputField.attr("max"))) {
                $inputField.val(parseInt($inputField.val()) + 1);
                if (typeof $inputField.data("product-id") !== 'undefined') {
                    updateCartItemQty($inputField.data("product-id"), $inputField.data("product-hash"), $inputField.val());
                }

            }
        });

        $inputField.on("input", function() {
            let inputValue = $inputField.val().replace(/\D/g, "");
            const maxInputValue = parseInt($inputField.attr("max"));

            if (inputValue > maxInputValue) {
                inputValue = maxInputValue;
            }

            $inputField.val(inputValue);
            if (typeof $inputField.data("product-id") !== 'undefined') {
                updateCartItemQty($inputField.data("product-id"), $inputField.data("product-hash"), $inputField.val());
            }

        });
    }

    let updateCartItemQty = function(productId, productHash, newQuantity) {
        $.ajax({
            url: "/card/update-cart",
            method: "POST",
            data: {
                product_hash: productHash,
                product_id: productId,
                quantity: newQuantity
            },
            dataType: "json",
            success: function(response) {
                if (response.success) {
                    var productElement = $(".totalProductSum[data-product-hash='" + productHash + "']");
                    productElement.text(parseFloat(response.total_product_sum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ','));
                    $(".totalSum").text(parseFloat(response.total_sum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ','));
                }

            },
            error: function(error) {
                console.log(error);
            }
        });
    }


    const getCartItemSum = function () {
        $.ajax({
            url: "/card/get-basket-sum",
            method: "GET",
            dataType: "json",
            success: function(response) {
                if (response.success) {
                    $(".totalSum").text(response.total_sum);
                }
            },
            error: function(error) {
                console.log(error);
            }
        });
    }




    $(".form-block").each(function() {
        setupFormBlock($(this));
    });


});
